import store from "@/store";
import {heureFormaterToMinutes,convertArrayToObject} from "@/utils/transform";
const getGroupsAndItemsForTimeline=  function(payload, inverted){
  if(inverted) {
    return getGroupsAndItemsForTimelineInverted(payload)
  } else {
    return getGroupsAndItemsForTimelineNormal(payload)
  }
};
const getGroupsAndItemsForTimelineNormal=  function(payload){
  // payload : one of
  //             - getPointagesParProjetsParPeriode
  //             - getPointagesParGroupeParPeriode
  //
  // Contruire les items et groupes pour les vues TimelinePointages
  const ressources = [];
  const groups = [];
  let items = [];
  let itemsRessource = [];
  let itemsProjet = [];
  let itemsTache = [];
  let groupsFormat = null;
  let itemsFormat = null;
  let idGroups = 0;
  let idItems = 0;
  let groupAsign = null;
  const pointages = [];
  let tachesRessource = [];
  let projetsRessource = [];
  let idRessources = [];
  let groupesTacheRessource = [];
  let groupesTacheRessourceFormat = null;
  let groupesProjetRessource = [];
  let groupesProjetRessourceFormat = null;
  let nestedGroups = [];
  let nomTache = null;
  let nomProjet = null;
  let nomCompletRessource = null;

  // récupération de tous les pointages
  payload.map((payl) => {
    const payloadFormat = {
      date: payl.date,
      dateCreationPointage: payl.dateCreationPointage,
      idPointage: payl.idPointage,
      heureDebut: payl.heureDebut,
      heureFin: payl.heureFin,
      nom: payl.nom,
      idTache: payl.idTache,
      idRessource: payl.idRessource,
      idProjet: payl.idProjet,
    };
    pointages.push(payloadFormat);
  });
  // récupération de toutes les ressources
  payload.map((payl) => {
    if (!ressources.includes(payl.idRessource)) {
      ressources.push(payl.idRessource);
    }
  });
  // Construction des groupes et des items pour chaque ressource
  for (let i = 0; i < ressources.length; i++) {
    // Construction des groupes pour les taches de chaque ressource
    for (let j = 0; j < pointages.length; j++) {
      if (
        ressources[i] === pointages[j].idRessource &&
        !tachesRessource.includes(pointages[j].idTache)
        // pour chaque ressource on regarde
        // si c'est son pointage et si tachesRessource
        // n'a pas déjà cet idTache en mémoire
      ) {
        if (pointages[j].nom.length > 45) {
          nomTache = pointages[j].nom.slice(0, 45) + "...";
        } else {
          nomTache = pointages[j].nom;
        }
        groupsFormat = {
          id: idGroups,
          content: nomTache,
          className: "tache",
          treeLevel: 3,
          idProjet: pointages[j].idProjet,
          idRessource: pointages[j].idRessource,
        };
        tachesRessource.push(pointages[j].idTache);
        groups.push(groupsFormat);
        groupesTacheRessourceFormat = {
          projet: pointages[j].idProjet,
          groupe: idGroups,
          idTache: pointages[j].idTache,
        };
        groupesTacheRessource.push(groupesTacheRessourceFormat);
        idGroups++;
      }
    }
    // Construction des items pour les taches de chaque ressource
    for (let j = 0; j < pointages.length; j++) {
      if (ressources[i] === pointages[j].idRessource) {
        for (let k = 0; k < groupesTacheRessource.length; k++) {
          if (groupesTacheRessource[k].idTache === pointages[j].idTache) {
            groupAsign = groupesTacheRessource[k].groupe;
          }
        }
        let deb;
        let fin = new Date(pointages[j].date + " " + pointages[j].heureFin);
        const limite = new Date(pointages[j].date + " " + "14:00:00");
        if (fin < limite) {
          deb = "01:00:00";
          fin = "12:30:00";
        } else {
          deb = "13:30:00";
          fin = "23:00:00";
        }
        let tempspointage = (heureFormaterToMinutes(pointages[j].heureFin) - heureFormaterToMinutes(pointages[j].heureDebut))/60
        itemsFormat = {
          id: idItems,
          group: groupAsign,
          className: "tache",
          start: pointages[j].date + " " + deb,
          end: pointages[j].date + " " + fin,
          content: tempspointage.toString(),
          idProjet: pointages[j].idProjet,
          idRessource: pointages[j].idRessource,
        };
        itemsTache.push(itemsFormat);
        idItems++;
      }
    }
    nestedGroups = [];
    // Construction des groupes pour les projets de chaque ressource
    for (let j = 0; j < pointages.length; j++) {
      if (
        ressources[i] === pointages[j].idRessource &&
        !projetsRessource.includes(pointages[j].idProjet)
      ) {
        const projetById =
          store.state.entreprise.projets[pointages[j].idProjet];
        for (let k = 0; k < groupesTacheRessource.length; k++) {
          if (groupesTacheRessource[k].projet === pointages[j].idProjet) {
            nestedGroups.push(groupesTacheRessource[k].groupe);
          }
        }
        nomProjet =
          projetById.nomProjet.length > 45
            ? projetById.nomProjet.slice(0, 45) + "..."
            : projetById.nomProjet;
        groupsFormat = {
          id: idGroups,
          content: nomProjet,
          className: "projet",
          treeLevel: 2,
          //https://codepen.io/jean2607/pen/jZWByv
          showNested: false,
          visible: true,
          nestedGroups: nestedGroups,
          idRessource: pointages[j].idRessource,
          idProjet: pointages[j].idProjet,
        };
        projetsRessource.push(pointages[j].idProjet);
        groups.push(groupsFormat);
        groupesProjetRessourceFormat = {
          idRessource: pointages[j].idRessource,
          groupe: idGroups,
        };
        groupesProjetRessource.push(groupesProjetRessourceFormat);
        idGroups++;
        nestedGroups = [];
      }
    }
    nestedGroups = [];
    // Construction des groupes pour les noms de chaque ressource
    for (let j = 0; j < pointages.length; j++) {
      if (
        ressources[i] === pointages[j].idRessource &&
        !idRessources.includes(pointages[j].idRessource)
      ) {
        const prenomNomRessource = store.getters["entreprise/getPrenomNom"](
          ressources[i]
        );
        for (let k = 0; k < groupesProjetRessource.length; k++) {
          if (
            groupesProjetRessource[k].idRessource === pointages[j].idRessource
          ) {
            nestedGroups.push(groupesProjetRessource[k].groupe);
          }
        }
        nomCompletRessource =
          prenomNomRessource.length > 45
            ? prenomNomRessource.slice(0, 45) + "..."
            : prenomNomRessource;
        groupsFormat = {
          id: idGroups,
          content: nomCompletRessource,
          className: "nom",
          treeLevel: 1,
          showNested: false,
          nestedGroups: nestedGroups,
          idRessource: pointages[j].idRessource,
          idProjet: null,
        };
        groups.push(groupsFormat);
        idRessources.push(pointages[j].idRessource);
        idGroups++;
      }
    }
    groupesProjetRessource = [];
    groupesTacheRessource = [];
    idRessources = [];
    tachesRessource = [];
    projetsRessource = [];
  }
  //Construction des items pour les projets
  for (let k = 0; k < groups.length; k++) {
  
    if (groups[k].className === "projet"){
        for (let j = 0; j < itemsTache.length; j++) {
          // items des projets
          if (        
            itemsTache[j].idProjet === groups[k].idProjet &&
            itemsTache[j].idRessource === groups[k].idRessource
          ) {
            itemsFormat = {
              id: idItems,
              group: groups[k].id,
              className: "projet",
              start: itemsTache[j].start,
              end: itemsTache[j].end,
              idProjet: itemsTache[j].idProjet,
              idRessource: itemsTache[j].idRessource,
              content: itemsTache[j].content,
            };
            let check=true;
            for(let i = 0; i < itemsProjet.length;i++) {
              //si deux item sont sur le meme jour on les concatene 
              if (itemsProjet[i].start.startsWith(itemsFormat.start.substring(0, 10)) &&
                 itemsProjet[i].end.startsWith(itemsFormat.end.substring(0, 10)) && 
                 itemsProjet[i].idProjet === itemsFormat.idProjet &&
                 itemsProjet[i].idRessource === itemsFormat.idRessource){
                //on adition les duree
                itemsProjet[i].content = (parseFloat(itemsProjet[i].content) + parseFloat(itemsFormat.content)).toString();
                //on set les date de debut et de fin 
                if(itemsProjet[i].start > itemsFormat.start)
                  itemsProjet[i].start = itemsFormat.start;
                if(itemsProjet[i].end < itemsFormat.end)
                  itemsProjet[i].end = itemsFormat.end;
                check=false;
              }
            }
            if(check){
              itemsProjet.push(itemsFormat);
              idItems++;
            }
          }
        }
      }
   
  }

  //Construction des items pour les ressources
  for (let k = 0; k < groups.length; k++) {
    if ( groups[k].className === "nom"){
      for (let j = 0; j < itemsProjet.length; j++) {
        // items des projets
      if (
        itemsProjet[j].idRessource === groups[k].idRessource &&
          groups[k].idProjet === null
        ) {
          itemsFormat = {
            id: idItems,
            group: groups[k].id,
            className: "nom",
            start: itemsProjet[j].start,
            end: itemsProjet[j].end,
            idProjet: itemsProjet[j].idProjet,
            idRessource: itemsProjet[j].idRessource,
            content: itemsProjet[j].content,
          };
          let check=true;
          for(let i = 0; i < itemsRessource.length;i++) {
          //si deux item sont sur le meme jour on les concatene 
          if (itemsRessource[i].start.startsWith(itemsFormat.start.substring(0, 10)) &&
          itemsRessource[i].end.startsWith(itemsFormat.end.substring(0, 10)) && 
          itemsRessource[i].idRessource === itemsFormat.idRessource){
          //on adition les duree
          itemsRessource[i].content = (parseFloat(itemsRessource[i].content) + parseFloat(itemsFormat.content)).toString();
          //on set les date de debut et de fin 
          if(itemsRessource[i].start > itemsFormat.start)
            itemsRessource[i].start = itemsFormat.start;
          if(itemsRessource[i].end < itemsFormat.end)
            itemsRessource[i].end = itemsFormat.end;
          check=false;
        }
          }
          if(check){
            itemsRessource.push(itemsFormat);
            idItems++;
          }
        }
      }
    }
  }
  items = [...itemsTache,...itemsProjet,...itemsRessource]
  // Post-processing, ajout total des heures par ligne
 
  let updatedGroups = [];
  if (groups && groups.length > 0 && items) {
    groups.forEach((group) => {
      let itemsDansGroupe = items.filter((item) => item.group === group.id);
      let sommeHeureParGroupe = 0;
      if (itemsDansGroupe.length > 0) {
        itemsDansGroupe.forEach((elem) => {
          sommeHeureParGroupe += parseFloat(elem.content)/getprofils(group.idRessource,elem.end.substring(0, 10));
          getprofils(group.idRessource,elem.end.substring(0, 10));
        });
      }
      updatedGroups.push({
        className: group.className,
        content:
          "<div class=''><span class='pr-1'>" +
          group.content +
          "</span>" +
          "<span class='send-to-right vignette-total-heures'>" +
        
          (sommeHeureParGroupe).toFixed(2).toString() + "J" +
            
          "</span></div>",
        id: group.id,
        idProjet: group.idProjet,
        idRessource: group.idRessource,
        nestedGroups: group.nestedGroups,
        showNested: group.showNested,
        visible: group.visible,
        treeLevel: group.treeLevel,
      });
    });
  }

  return [items, updatedGroups];
};

const getGroupsAndItemsForTimelineInverted=  function(payload){
  // payload : one of
  //             - getPointagesParProjetsParPeriode
  //             - getPointagesParGroupeParPeriode
  //
  // Contruire les items et groupes pour les vues TimelinePointages
  const projets = [];
  const groups = [];
  let items = [];
  let itemsRessource = [];
  let itemsProjet = [];
  let itemsTache = [];
  let groupsFormat = null;
  let itemsFormat = null;
  let idGroups = 0;
  let idItems = 0;
  let groupAsign = null;
  const pointages = [];
  let tachesProjet = [];
  let ressourcesProjet = [];
  let idProjets = [];
  let groupesTacheProjet = [];
  let groupesTacheProjetFormat = null;
  let groupesRessourceProjet = [];
  let groupesRessourceProjetFormat = null;
  let nestedGroups = [];
  let nomTache = null;
  let nomProjet = null;
  let nomCompletRessource = null;

  // récupération de tous les pointages
  payload.map((payl) => {
    const payloadFormat = {
      date: payl.date,
      dateCreationPointage: payl.dateCreationPointage,
      idPointage: payl.idPointage,
      heureDebut: payl.heureDebut,
      heureFin: payl.heureFin,
      nom: payl.nom,
      idTache: payl.idTache,
      idRessource: payl.idRessource,
      idProjet: payl.idProjet,
      idRessourceTache: payl.idRessource+payl.idTache,
    };
    pointages.push(payloadFormat);
  });
  // // récupération de toutes les ressources
  // payload.map((payl) => {
  //   if (!ressources.includes(payl.idRessource)) {
  //     ressources.push(payl.idRessource);
  //   }
  // });
  // récupération de tous les projets
  payload.map((payl) => {
    if (!projets.includes(payl.idProjet)) {
      projets.push(payl.idProjet);
    }
  });

  // Construction des groupes et des items pour chaque projet
  for (let i = 0; i < projets.length; i++) {
    // Construction des groupes pour les taches de chaque projet
    for (let j = 0; j < pointages.length; j++) {
      if (
        projets[i] === pointages[j].idProjet &&
        !tachesProjet.includes(pointages[j].idRessourceTache)
        // pour chaque ressource on regarde
        // si c'est son pointage et si tachesRessource
        // n'a pas déjà cet idTache en mémoire
      ) {
        if (pointages[j].nom.length > 45) {
          nomTache = pointages[j].nom.slice(0, 45) + "...";
        } else {
          nomTache = pointages[j].nom;
        }
        groupsFormat = {
          id: idGroups,
          content: nomTache,
          className: "tache",
          treeLevel: 3,
          idProjet: pointages[j].idProjet,
          idRessource: pointages[j].idRessource,
        };
        tachesProjet.push(pointages[j].idRessourceTache);
        groups.push(groupsFormat);
        groupesTacheProjetFormat = {
          ressource: pointages[j].idRessource,
          groupe: idGroups,
          idTache: pointages[j].idTache,
          idRessource: pointages[j].idRessource,
          idRessourceTache: pointages[j].idRessourceTache,
        };
        groupesTacheProjet.push(groupesTacheProjetFormat);
        idGroups++;
      }
    }
    // Construction des items pour les taches de chaque projet
    for (let j = 0; j < pointages.length; j++) {
      if (projets[i] === pointages[j].idProjet) {
        for (let k = 0; k < groupesTacheProjet.length; k++) {
          if (groupesTacheProjet[k].idRessourceTache === pointages[j].idRessourceTache) {
            groupAsign = groupesTacheProjet[k].groupe;
          }
        }
        let deb;
        let fin = new Date(pointages[j].date + " " + pointages[j].heureFin);
        const limite = new Date(pointages[j].date + " " + "14:00:00");
        if (fin < limite) {
          deb = "01:00:00";
          fin = "12:30:00";
        } else {
          deb = "13:30:00";
          fin = "23:00:00";
        }
        let tempspointage = (heureFormaterToMinutes(pointages[j].heureFin) - heureFormaterToMinutes(pointages[j].heureDebut))/60
        itemsFormat = {
          id: idItems,
          group: groupAsign,
          className: "tache",
          start: pointages[j].date + " " + deb,
          end: pointages[j].date + " " + fin,
          content: tempspointage.toString(),
          idProjet: pointages[j].idProjet,
          idRessource: pointages[j].idRessource,
        };
        itemsTache.push(itemsFormat);
        idItems++;
      }
    }
    nestedGroups = [];
    // Construction des groupes pour les ressources de chaque projet
    for (let j = 0; j < pointages.length; j++) {
      if (
        projets[i] === pointages[j].idProjet &&
        !ressourcesProjet.includes(pointages[j].idRessource)
      ) {
        const prenomNomRessource = store.getters["entreprise/getPrenomNom"](
          pointages[j].idRessource
        );
        for (let k = 0; k < groupesTacheProjet.length; k++) {
          if (groupesTacheProjet[k].ressource === pointages[j].idRessource) {
            nestedGroups.push(groupesTacheProjet[k].groupe);
          }
        }
        nomCompletRessource =
          prenomNomRessource.length > 45
            ? prenomNomRessource.slice(0, 45) + "..."
            : prenomNomRessource;
        groupsFormat = {
          id: idGroups,
          content: nomCompletRessource,
          className: "nom",
          treeLevel: 2,
          //https://codepen.io/jean2607/pen/jZWByv
          showNested: false,
          visible: true,
          nestedGroups: nestedGroups,
          idRessource: pointages[j].idRessource,
          idProjet: pointages[j].idProjet,
        };
        ressourcesProjet.push(pointages[j].idRessource);
        groups.push(groupsFormat);
        groupesRessourceProjetFormat = {
          idProjet: pointages[j].idProjet,
          groupe: idGroups,
        };
        groupesRessourceProjet.push(groupesRessourceProjetFormat);
        idGroups++;
        nestedGroups = [];
      }
    }
    nestedGroups = [];
    // Construction des groupes pour les projets
    for (let j = 0; j < pointages.length; j++) {
      if (
        projets[i] === pointages[j].idProjet &&
        !idProjets.includes(pointages[j].idProjet)
      ) {
        const projetById =
          store.state.entreprise.projets[pointages[j].idProjet];
        for (let k = 0; k < groupesRessourceProjet.length; k++) {
          if (
            groupesRessourceProjet[k].idProjet === pointages[j].idProjet
          ) {
            nestedGroups.push(groupesRessourceProjet[k].groupe);
          }
        }
        nomProjet =
          projetById.nomProjet.length > 45
            ? projetById.nomProjet.slice(0, 45) + "..."
            : projetById.nomProjet;
        groupsFormat = {
          id: idGroups,
          content: nomProjet,
          className: "projet",
          treeLevel: 1,
          showNested: false,
          nestedGroups: nestedGroups,
          idRessource: null,
          idProjet: pointages[j].idProjet,
        };
        groups.push(groupsFormat);
        idProjets.push(pointages[j].idProjet);
        idGroups++;
      }
    }
    groupesRessourceProjet = [];
    groupesTacheProjet = [];
    idProjets = [];
    tachesProjet = [];
    ressourcesProjet = [];
  }
  //Construction des items pour les ressources
  for (let k = 0; k < groups.length; k++) {
  
    if (groups[k].className === "nom"){
        for (let j = 0; j < itemsTache.length; j++) {
          // items des projets
          if (        
            itemsTache[j].idProjet === groups[k].idProjet &&
            itemsTache[j].idRessource === groups[k].idRessource
          ) {
            itemsFormat = {
              id: idItems,
              group: groups[k].id,
              className: "nom",
              start: itemsTache[j].start,
              end: itemsTache[j].end,
              idProjet: itemsTache[j].idProjet,
              idRessource: itemsTache[j].idRessource,
              content: itemsTache[j].content,
            };
            let check=true;
            for(let i = 0; i < itemsRessource.length;i++) {
              //si deux item sont sur le meme jour on les concatene 
              if (itemsRessource[i].start.startsWith(itemsFormat.start.substring(0, 10)) &&
                itemsRessource[i].end.startsWith(itemsFormat.end.substring(0, 10)) && 
                itemsRessource[i].idProjet === itemsFormat.idProjet &&
                itemsRessource[i].idRessource === itemsFormat.idRessource){
                //on adition les duree
                itemsRessource[i].content = (parseFloat(itemsRessource[i].content) + parseFloat(itemsFormat.content)).toString();
                //on set les date de debut et de fin 
                if(itemsRessource[i].start > itemsFormat.start)
                  itemsRessource[i].start = itemsFormat.start;
                if(itemsRessource[i].end < itemsFormat.end)
                  itemsRessource[i].end = itemsFormat.end;
                check=false;
              }
            }
            if(check){
              itemsRessource.push(itemsFormat);
              idItems++;
            }
          }
        }
      }
   
  }

  //Construction des items pour les projets
  for (let k = 0; k < groups.length; k++) {
    if ( groups[k].className === "projet"){
      for (let j = 0; j < itemsRessource.length; j++) {
        // items des projets
      if (
        itemsRessource[j].idProjet === groups[k].idProjet &&
          groups[k].idRessource === null
        ) {
          itemsFormat = {
            id: idItems,
            group: groups[k].id,
            className: "projet",
            start: itemsRessource[j].start,
            end: itemsRessource[j].end,
            idProjet: itemsRessource[j].idProjet,
            idRessource: itemsRessource[j].idRessource,
            content: itemsRessource[j].content,
          };
          let check=true;
          for(let i = 0; i < itemsProjet.length;i++) {
            //si deux item sont sur le meme jour on les concatene 
            if (itemsProjet[i].start.startsWith(itemsFormat.start.substring(0, 10)) &&
              itemsProjet[i].end.startsWith(itemsFormat.end.substring(0, 10)) && 
              itemsProjet[i].idProjet === itemsFormat.idProjet){
              //on adition les duree
              itemsProjet[i].content = (parseFloat(itemsProjet[i].content) + parseFloat(itemsFormat.content)).toString();
              //on set les date de debut et de fin 
              if(itemsProjet[i].start > itemsFormat.start)
              itemsProjet[i].start = itemsFormat.start;
              if(itemsProjet[i].end < itemsFormat.end)
              itemsProjet[i].end = itemsFormat.end;
              check=false;
            }
          }
          if(check){
            itemsProjet.push(itemsFormat);
            idItems++;
          }
        }
      }
    }
  }
  items = [...itemsTache,...itemsProjet,...itemsRessource]
  // Post-processing, ajout total des heures par ligne
 
    let updatedGroups = [];
    let elemJour;
  if (groups && groups.length > 0 && items) {
    groups.forEach((group) => {
      let itemsDansGroupe = items.filter((item) => item.group === group.id);
      let sommeHeureParGroupe = 0;
      if (itemsDansGroupe.length > 0) {
        itemsDansGroupe.forEach((elem) => {
          if (!getprofils(group.idRessource,elem.end.substring(0, 10))) {
            elemJour = (Math.round((parseFloat(elem.content)/7.4)*2, 0))/2;
            sommeHeureParGroupe +=elemJour;
            7.4;      
          } else {       
            sommeHeureParGroupe += parseFloat(elem.content)/getprofils(group.idRessource,elem.end.substring(0, 10));
            getprofils(group.idRessource,elem.end.substring(0, 10));
          }
        });
      }
      updatedGroups.push({
        className: group.className,
        content:
          "<div class=''><span class='pr-1'>" +
          group.content +
          "</span>" +
          "<span class='send-to-right vignette-total-heures'>" +   
          (sommeHeureParGroupe).toFixed(2).toString() + "J" +      
          "</span></div>",
        id: group.id,
        idProjet: group.idProjet,
        idRessource: group.idRessource,
        nestedGroups: group.nestedGroups,
        showNested: group.showNested,
        visible: group.visible,
        treeLevel: group.treeLevel,
      });
    });
  }
  return [items, updatedGroups];
};

const getprofils = (idRessource,date)=>{
  if(idRessource != null) {
  let changementRole = convertArrayToObject(store.state.entreprise.utilisateurs[idRessource].changementRole,"dateChangement");
  let changementRoleDate = (Object.keys(changementRole)).sort();
  changementRoleDate = changementRoleDate.filter(datechangement => datechangement>date)
  let idRole;
  if(changementRoleDate.length==0){
    idRole= store.state.entreprise.utilisateurs[idRessource].idRole;
  }
  else{
    idRole = changementRole[changementRoleDate[0]].idAncienRole
  }
  
  let tempsTravaille=store.state.entreprise.profils[[
    store.getters["entreprise/getEntreprisebyIdRessource"](idRessource),
    idRole
  ]].tempsTravaille;
    return tempsTravaille;
  } else {
    return 0
  }
}


export { getGroupsAndItemsForTimeline };
