<template>
  <v-container fluid class="ma-0 pa-0 heritage-scroll flex-column">
    <!-- Choisir le mois -->
    <v-row no-gutters class="align-center justify-center">
      <v-btn @click="loadAllPointagesGlobauxMoins">
        <v-icon> mdi-chevron-left </v-icon>
        Mois précédent
      </v-btn>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-sheet :rounded="true"> -->
          <v-text-field
            v-model="moisSelectionneISOString"
            label="Sélectionner le mois"
            prepend-icon="mdi-calendar"
            readonly
            class="px-8"
            style="max-width: 285px"
            dark
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
          <!-- </v-sheet> -->
        </template>
        <v-date-picker
          v-model="moisSelectionneISOString"
          type="month"
          no-title
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu>
      <v-btn @click="loadAllPointagesGlobauxPlus">
        Mois suivant
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-row>
    <v-row no-gutters class="align-center justify-center" style="margin-bottom: 10px; padding-top: 0 !important;">
      <v-switch
        v-model="tri_inverse"
        style="margin-top: 0%;  padding-top: 0 !important;"
        inset
        hide-details
        color="green"
        :label="tri_inverse ? 'Tri par projet activé' : 'Tri par projet désactivé'"
        @change="chargerLesDonnees"
      ></v-switch>
    </v-row>

    <v-skeleton-loader
      v-if="loading"
      :loading="loading"
      type="article, list-item-two-line, actions"
      style="background-color: white"
    />
    <v-card v-else class="scrollable-content">
      <div v-if="items.length">
        <timeline
          v-if="!loading"
          ref="timeline"
          :items="items"
          :groups="groups"
          :options="options"
        ></timeline>
      </div>
      <div v-else>
        <h2 class="nodata">Pas de pointage ce mois</h2>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Api from "@/api/kuberact";
import { getGroupsAndItemsForTimeline } from "@/utils/legacy";
import { Timeline } from "vue-visjs";

export default {
  components: {
    Timeline,
  },
  props: {
    type: {
      type: String,
      default: "projets", // projets || groupe
    },
  },
  data: () => ({
    loading: true,
    moisSelectionneISOString: new Date().toISOString().substr(0, 7), // 'YYY-MM'
    menu: false,
    groups: [],
    items: [],
    tri_inverse: false,
    color_lvl1: "#004087 !important",
    color_lvl2: "#3e7abe !important"
  }),
  computed: {
    ...mapState("entreprise", ["projets"]),
    ...mapGetters("utilisateur", ["favoris"]),
    ...mapState("utilisateur", ["configuration"]),
    options: function() {
      // Doc: https://visjs.github.io/vis-timeline/docs/timeline/
      return {
        editable: false,
        orientation: "both",
        type: "background",
        stackSubgroups: false,
        align: "center",
        maxMinorChars: 4,
        zoomable: false,
        // zoomFriction: 7,
        min: this.dateDebutMoisISOString,
        max: this.dateDebutMoisProchainISOString,
        start: this.dateDebutMoisISOString,
        end: this.dateDebutMoisProchainISOString,
        showMajorLabels: false,
        // zoomMin: 31 * 24 * 60 * 60 * 1000, // zoom max = 31 jours
        // zoomMax: 31 * 24 * 60 * 60 * 1000, // zoom min = 31 jours
      };
    },
    date: function() {
      return new Date(this.moisSelectionneISOString);
    },
    dateDebutMoisISOString: function() {
      return this.date.toISOString().substr(0, 10);
    },
    dateFinMoisISOString: function() {
      return new Date(
        Date.UTC(
          this.date.getUTCFullYear(),
          this.date.getUTCMonth() + 1,
          this.date.getUTCDate() - 1
        )
      )
        .toISOString()
        .substr(0, 10);
    },
    dateDebutMoisProchainISOString: function() {
      return new Date(
        Date.UTC(this.date.getUTCFullYear(), this.date.getUTCMonth() + 1)
      )
        .toISOString()
        .substr(0, 10);
    },
    //
  },
  watch: {
    moisSelectionneISOString: function() {
      this.chargerLesDonnees();
    },
    //
  },
  created() {
    //
  },
  mounted() {
    this.chargerLesDonnees();
    //
  },
  destroyed() {
    if (this.type === "groupe") {
      this.clearIdGroupeSelectionne();
    }
  },
  methods: {
    ...mapActions("utilisateur", ["clearIdGroupeSelectionne"]),
    chargerLesDonnees() {
      this.loading = true;

      let fonctionRecuperation;
      let cible;
      if (this.type === "groupe") {
        fonctionRecuperation = Api.getPointagesParGroupeParPeriode;
        cible = this.configuration.idGroupeSelectionne;
      } else {
        // default to 'projets'
        //TODO suprimer mettre a jour les favorise au demaillage d'une ressource
        fonctionRecuperation = Api.getPointagesParProjetsParPeriode;
        cible = this.favoris.filter((favori) => favori in this.projets);
      }

      fonctionRecuperation(
        cible,
        this.dateDebutMoisISOString,
        this.dateFinMoisISOString
      )
        .then((response) => {
          [this.items, this.groups] = getGroupsAndItemsForTimeline(
              response.data, this.tri_inverse
            );
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
    //
    loadAllPointagesGlobauxMoins() {
      let tmp = new Date(
        Date.UTC(this.date.getUTCFullYear(), this.date.getUTCMonth() - 1)
      );
      this.moisSelectionneISOString = tmp.toISOString().substr(0, 7);
    },
    loadAllPointagesGlobauxPlus() {
      let tmp = new Date(
        Date.UTC(this.date.getUTCFullYear(), this.date.getUTCMonth() + 1)
      );
      this.moisSelectionneISOString = tmp.toISOString().substr(0, 7);
    },
  },
};
</script>

<style>
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
  background-color: #999999 !important;
}
.vis-item.nom {
  background-color: #004087 !important;
  color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.vis-item.nom:hover,
.vis-item.tache:hover {
  background-color: #001b3a !important;
  color: white;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
}
.vis-item.tache {
  background-color: #e3e3ff !important;
  color: black;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.vis-item.projet {
  background-color: #3e7abe !important;
  color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.vis-item.projet:hover {
  background-color: #001b3a !important;
  color: white;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
}

.vis-group.tache:hover {
  background-color: #ebebeb81 !important;
}
.vis-group.nom:hover {
  background-color: #003f8759 !important;
}

.vis-time-axis .vis-text.vis-saturday,
.vis-time-axis .vis-text.vis-sunday {
  color: white;
}

.vis-timeline {
  border: 1px solid #004087;
  font-size: 12pt;
}

.vis-label.vis-nested-group.nom {
  background-color: #004087 !important;
  color: white;
}
.vis-label.vis-nested-group.projet {
  background-color: #3e7abe !important;
  color: white;
}

.vis-label.vis-nested-group.vis-group-level-3 {
  background-color: #e3e3ff !important;
  color: black;
}
.vis-time-axis {
  color: black;
}
#noPointage {
  color: #004087;
  margin-bottom: 2%;
  text-align: center;
}
.vignette-total-heures {
  min-width: 64px;
  text-align: right;
  border: solid;
  border-width: 1px;
  border-radius: 10%;
  float: right;
  padding-right: 5px;
  padding-left: 5px;
  
}
.vis-inner {
  width: calc(100% - 15px);
  /* 15px corresponds to the size of the before arrow in foldable tasks */
}
.vis-group-level-3 .vis-inner {
  width: 100%;
}

.nodata {
  color: #004087;
  margin-bottom: 1%;
  margin-top: 1%;
  text-align: center;
}
</style>
